/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import ContentPost from "@components/ContentPost"
import ListingGrid from "@components/ListingGrid"
import Section from "@components/Section"
import BannerBlock from "@components/BannerBlock"
import AccordionWithBanner from "@components/Accordion/WithBanner"
import slugify from "slugify"

export default function Template ({
  data, // this prop will be injected by the GraphQL query below.
  ...props
}) {
  const { workshopData, pageData } = data
  const { moreWorkshops } = props.pageContext
  const { ogTitle, ogdescription, ogimage } = workshopData.frontmatter
  return (
    <Layout>
      <SEO
        title={ogTitle || workshopData.frontmatter.title}
        pathname={props.location.pathname}
        image={ogimage || workshopData.frontmatter.image}
        description={ogdescription || workshopData.frontmatter.description}
      />
      <Section
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <ContentPost
          {...workshopData.frontmatter}
          type="workshop"
          workshopType={workshopData.frontmatter.workshopType}
        />
      </Section>
      <AccordionWithBanner
        title="More about workshops"
        items={pageData.frontmatter.accordion.accordionItems}
      />

      <div
        sx={{
          bg: "pink",
        }}
      >
        {/* <Section>
          <BannerBlock title="More workshops" noMb />

          {moreWorkshops && moreWorkshops.length ? (
            <ListingGrid
              items={moreWorkshops.map(item => ({
                title: item.node.frontmatter.title,
                description: item.node.frontmatter.description,
                link: `/workshops/${
                  item.node.frontmatter.workshopType
                }/${slugify(item.node.frontmatter.title.toLowerCase())}`,
              }))}
            />
          ) : null}
        </Section> */}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!, $workshopType: String!) {
    workshopData: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        workshopType
        title
        image
        description
        outcomes
        duration
        audience
        capacity
        cost
        ctaButton {
          label
          link
        }
        ogTitle
        ogdescription
        ogimage
      }
    }
    pageData: markdownRemark(
      fileAbsolutePath: { glob: "**/pages/workshops/*.md" }
      frontmatter: { workshopType: { eq: $workshopType } }
    ) {
      frontmatter {
        accordion {
          ...Accordion
        }
      }
    }
  }
`
