/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useEffect } from "react"
import styled from "styled-components"
import $ from "jquery"
import IconLink from "@components/IconLink"
import PropTypes from "prop-types"
import Container from "@components/Container"
import { rem } from "@src/theme"
import ButtonLink from "@components/ButtonLink"
import { BodyLarge } from "@components/primitives/Text"
import ImageBannerCard from "@components/ImageBannerCard"
import DescriptionList from "@components/DescriptionList"
import MarkdownBody from "@modules/MarkdownBody"
import Spacer from "@components/Spacers"
import { Themed } from '@theme-ui/mdx'

const ContentPost = ({ type, ...props }) => {
  if (type === "workshop") {
    return <WorkshopPost {...props} />
  }

  if (type === "position") {
    return <PositionPost {...props} />
  }

  if (type === "event") {
    return <EventPost {...props} />
  }

  return null
}

const List = styled.ul`
  margin: 0;
  margin-left: 20px;
  padding: 0;
  li {
    line-height: 1.5;
  }
`

ContentPost.propTypes = {
  type: PropTypes.string,
}

const WorkshopPost = ({
  title,
  outcomes,
  duration,
  audience,
  capacity,
  cost,
  description,
  workshopType,
  ctaButton
}) => (
  <Container
    sx={{
      maxWidth: rem(842),
      bg: "white",
    }}
  >
    <div
      sx={{
        bg: "purple",
      }}
    >
      <div
        sx={{
          p: ["5vw", rem(64)],
        }}
      >
        <Themed.h1
          sx={{
            my: 0,
            color: "pink",
            wordBreak: ["break-all", "initial"],
          }}
        >
          {title}
        </Themed.h1>
      </div>
    </div>
    <div
      sx={{
        p: ["5vw", rem(64)],
      }}
    >
      <BodyLarge
        style={{
          mt: 0,
          mb: rem(64),
        }}
      >
        {description}
      </BodyLarge>

      <section
        sx={{
          mb: rem(64),
        }}
      >
        <Themed.h4 sx={{ mb: rem(24), fontSize: rem(18) }}>
          Learning outcomes:
        </Themed.h4>
        <List>
          {outcomes.split("|").map(outcome => (
            <li>{outcome}</li>
          ))}
        </List>
      </section>

      <section
        sx={{
          width: ["100%", null, "50%"],
        }}
      >
        <div
          sx={{
            mb: 7,
          }}
        >
          <DescriptionList
            items={[
              {
                term: "Size",
                definition: capacity,
              },
              {
                term: "For",
                definition: audience,
              },
              {
                term: "Duration",
                definition: duration,
              },
              {
                term: "Cost",
                definition: cost,
              },
            ]}
          />
        </div>
      </section>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: ["flex-start", null, "center"],
          flexDirection: ["column", null, "row"],
        }}
      >
        {ctaButton && ctaButton.label ? <ButtonLink to={ctaButton.link}>{ctaButton.label}</ButtonLink> : (<ButtonLink to="/workshops/book-now">Get in touch to book</ButtonLink>)}
        <Spacer.Small
          styles={{
            display: ["block", null, "none"],
          }}
        />
        <IconLink
          link={`/training-and-speakers`}
          type="arrowLeft"
          label={`Back to all workshops`}
        />
      </div>
    </div>
  </Container>
)

WorkshopPost.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  outcomes: PropTypes.string,
  duration: PropTypes.string,
  audience: PropTypes.string,
  capacity: PropTypes.string,
  cost: PropTypes.string,
}

const PositionPost = ({
  title,
  location,
  applicationDeadline,
  image,
  description,
  link,
  html,
  ...props
}) => (
  <Container
    sx={{
      maxWidth: rem(842),
      bg: "white",
    }}
  >
    <div
      sx={{
        bg: "purple",
      }}
    >
      <div
        sx={{
          p: ["5vw", rem(64)],
        }}
      >
        <Themed.h1
          sx={{
            my: 0,
            color: "pink",
            wordBreak: ["break-all", "initial"],
          }}
        >
          {title}
        </Themed.h1>
      </div>
    </div>
    <div
      sx={{
        p: ["5vw", rem(64)],
      }}
    >
      <MarkdownBody html={html} />

      <section
        sx={{
          width: ["100%", null, "50%"],
        }}
      >
        <div
          sx={{
            mb: 7,
          }}
        >
          <DescriptionList
            items={[
              {
                term: "Location",
                definition: location,
              },
              {
                term: "Applications close",
                definition: applicationDeadline,
              },
            ]}
          />
        </div>
        <ButtonLink to={link} external>
          Apply now
        </ButtonLink>
      </section>
    </div>
  </Container>
)

PositionPost.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  applicationDeadline: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

const EventPost = ({ title, image, description, link }) => {
  useEffect(() => {
    $("div.event-html *").removeAttr("style")
  }, [])

  return (
    <Container
      sx={{
        maxWidth: rem(842),
      }}
    >
      <ImageBannerCard title={title} image={image}>
        <section
          sx={{
            mb: 5,
          }}
        >
          <MarkdownBody html={description} className="event-html" />
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: ["flex-start", null, "center"],
              flexDirection: ["column", null, "row"],
            }}
          >
            <ButtonLink to={link} external>
              Register your spot
            </ButtonLink>

            <Spacer.Small
              styles={{
                display: ["block", null, "none"],
              }}
            />
            <IconLink
              link="/events"
              type="arrowLeft"
              label="Back to all events"
            />
          </div>
        </section>
      </ImageBannerCard>
    </Container>
  )
}

EventPost.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

export default ContentPost
